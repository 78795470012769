<template>
  <div class="page">
    <div
      class="item"
      v-for="(item, i) in courseList"
      :key="i"
      @click="goTo(item.to)"
    >
      <img src="@/assets/course-banner.png" alt="" class="banner" />
      <h3 class="name">{{ item.name }}</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "Course",
  data() {
    return {
      courseList: [
        { name: "气象STEM课程总体介绍", to: "courseIntro" },
        { name: "气象STEM课程第一学期介绍", to: "" },
        { name: "气象STEM课程第二学期介绍", to: "" },
        { name: "气象STEM课程第三学期介绍", to: "" },
      ],
    };
  },
  methods: {
    goTo(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  padding: 59px 37px;
  width: 1432px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item {
    cursor: pointer;
    width: 333px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .banner {
      width: 100%;
      margin-bottom: -4px;
    }
    .name {
      width: 291px;
      line-height: 114px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      background: url("../assets/course-name.png") center no-repeat;
      background-size: auto 100%;
    }
  }
}
</style>